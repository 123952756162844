import React, { Fragment } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { InstallButton, api, pilihMenu, pesan } from '../Module';
import { requestForToken, onMessageListener } from '../firebase';
// GURU
import Home from "./HomeMurid";
import Absen from "./AbsenMurid";
import Scan from "./Scan";
import Info from "./Info";
import Profile from "./ProfileMurid";
// END GURU

class MainGuru extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Profile: {},
            Path: "/home",
            show: false,
            notification: { title: '', body: '' },
            tokenFound: false,
            Menu: [
                { path: "/home", nama: "Home", icon: "fas fa-home", id: "menuHome" },
                { path: "/absen", nama: "Absen", icon: "fas fa-fingerprint", id: "menuAbsen" },
                { path: "/scan", nama: "Scan", icon: "fas fa-qrcode", id: "menuScan" },
                { path: "/info", nama: "Info", icon: "fas fa-bell", id: "menuInfo" },
                { path: "/profile", nama: "Profil", icon: "fas fa-user", id: "menuProfile" }
            ]
        };
    }

    async componentDidMount() {
        let TokenAndroid = localStorage.getItem("TokenFirebase");
        let openFrom = localStorage.getItem("OpenFrom");
        setTimeout(async () => {
            if (TokenAndroid != "" || TokenAndroid != undefined || TokenAndroid != null || TokenAndroid != false) await api("model/tu_crud", { act: "update token", TokenFirebase: TokenAndroid });
        }, 5000);
        let Path = window.location.pathname;
        this.setState({ Path: Path.replaceAll("#", "") });
        if (openFrom == "Web") requestForToken(this.setTokenFound);
    }

    setTokenFound = async (tokenFound, token) => {
        if (tokenFound) {
            await api("controler/datasiswa_api", { act: "set token", TokenFireBase: token }, true);
            this.setState({ tokenFound });
        }
    };

    render() {
        return (
            <Router>
                <div className="content">
                    <Routes>
                        <Route path="/" element={<Home page="Home" />} />
                        <Route path="/home" element={<Home page="Home" />} />
                        <Route path="/absen" element={<Absen page="Absen" />} />
                        <Route path="/scan" element={<Scan page="Scan" />} />
                        <Route path="/info" element={<Info page="Info" />} />
                        <Route path="/profile" element={<Profile page="Profile" />} />
                    </Routes>
                </div>

                <div className="menu-container">
                    <div className="menu">
                        {
                            this.state.Menu.map((menu, i) => {
                                return (
                                    <Link to={menu.path} id={menu.id} className={this.state.Path == menu.path ? "menu-item active-menu" : "menu-item"} key={i} onClick={() => {
                                        document.getElementById('loadingSpiner').style.display = "block";
                                        pilihMenu(menu.id);
                                        this.setState({ Path: menu.path });
                                    }}>
                                        <i className={menu.icon}></i>
                                        <div className="menu-label">{menu.nama}</div>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
                <InstallButton />
                <div className="spinner-border text-warning" id="loadingSpiner" role="status" style={{ position: "fixed", bottom: "98px", right: "20px", width: "40px", height: "40px" }}>
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div id="toast" className="hidden"></div>
            </Router >
        )
    }
}

export default MainGuru;
