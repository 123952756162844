import React, { Fragment } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { InstallButton } from '../Module';
// GURU
import Home from "./HomeGuru";
import Absen from "./AbsenGuru";
import Scan from "./Scan";
import Info from "./Academy";
import Profile from "./ProfileGuru";
// END GURU

class MainGuru extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Profile: {},
            Path: "/home"
        };
    }

    async componentDidMount() {
        let Path = window.location.pathname;
    }

    render() {
        return (
            <Router>
                <div className="content">
                    <Routes>
                        <Route path="/" element={<Home page="Home" />} />
                        <Route path="/home" element={<Home page="Home" />} />
                        <Route path="/absen" element={<Absen page="Absen" />} />
                        <Route path="/scan" element={<Scan page="Scan" />} />
                        <Route path="/info" element={<Info page="Info" />} />
                        <Route path="/profile" element={<Profile page="Profile" />} />
                    </Routes>
                </div>

                <div className="menu-container">
                    <div className="menu">
                        <Link to="/home" id="menuHome" className={`menu-item ${this.state.Path == "/home" ? "active-menu" : ""}`} onClick={() => this.setState({ Path: "/home" })}>
                            <i className="fas fa-home"></i>
                            <div className="menu-label">Home</div>
                        </Link>
                        <Link to="/absen" className={`menu-item ${this.state.Path == "/absen" ? "active-menu" : ""}`} onClick={() => this.setState({ Path: "/absen" })}>
                            <i className="fas fa-fingerprint"></i>
                            <div className="menu-label">Absen</div>
                        </Link>
                        <Link to="/scan" className={`menu-item ${this.state.Path == "/scan" ? "active-menu" : ""}`} onClick={() => this.setState({ Path: "/scan" })}>
                            <i className="fas fa-qrcode"></i>
                            <div className="menu-label">Scan</div>
                        </Link>
                        <Link to="/info" className={`menu-item ${this.state.Path == "/info" ? "active-menu" : ""}`} onClick={() => this.setState({ Path: "/info" })}>
                            <i className="fas fa-book-reader"></i>
                            <div className="menu-label">Academy</div>
                        </Link>
                        <Link to="/profile" className={`menu-item ${this.state.Path == "/profile" ? "active-menu" : ""}`} onClick={() => this.setState({ Path: "/profile" })}>
                            <i className="fas fa-user"></i>
                            <div className="menu-label">Profile</div>
                        </Link>
                    </div>
                </div>
                <InstallButton />
                <div className="spinner-border text-warning" id="loadingSpiner" role="status" style={{ position: "fixed", bottom: "98px", right: "20px", width: "40px", height: "40px" }}>
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div id="toast" className="hidden"></div>
            </Router>
        )
    }
}

export default MainGuru;
