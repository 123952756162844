import React, { Fragment } from 'react';
import { pesan, submitForm, api, saiki, exportData, openModal } from '../Module';
import QRCode from 'qrcode.react';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DataPelajaran: [],
            DataEks: [],
            DataMaPel: [],
            DetailPelajaran: [],
            Tanggal: saiki(),
            JamMulai: "07:00",
            JamSelesai: "09:00",
            IDPelajaran: 0,
            Notes: "",
            ID: "",
            host: window.location.hostname === "localhost" ? "http://localhost/siswaapi" : "https://siswaapi.naylatools.com"
        };
    }

    async componentDidMount() {
        this.handleMain();
        document.getElementById('loadingSpiner').style.display = "block";
    }

    async handleMain() {
        let sql = await api("dataguru", { act: "data jadwal" });
        if (sql.status == "sukses") this.setState({
            DataPelajaran: sql.pelajaran,
            DataEks: sql.eks,
            Tanggal: saiki(),
            JamMulai: "07:00",
            JamSelesai: "09:00",
            IDPelajaran: 0,
            Notes: "",
            ID: "",
        });
        document.getElementById('loadingSpiner').style.display = "none";
    }

    async handleFormPelajaran(ID = "") {
        document.getElementById('btnCloseDetail').click();
        let sql = await api("dataguru", { act: "data belajar", ID: ID });
        if (sql.status == "sukses") {
            if (sql.data != false) {
                this.setState({
                    DataMaPel: sql.Mapel,
                    ID: ID,
                    Tanggal: sql.data.Tanggal,
                    JamMasuk: sql.data.JamMasuk,
                    JamPulang: sql.data.JamPulang,
                    IDPelajaran: sql.data.IDPelajaran,
                    Notes: sql.data.Notes
                });
            } else {
                this.setState({ DataMaPel: sql.Mapel, ID: ID });
            }
            openModal("modalAdd");
        } else {
            pesan(sql.pesan);
        }
    }

    async handleDetailBelajar(ID) {
        let sql = await api("dataguru", { act: "detail jadwal pelajaran", ID: ID });
        if (sql.status == "sukses") {
            this.setState({ DetailPelajaran: sql.data, ID: ID });
            openModal("modalDetail");
        } else {
            pesan(sql.pesan);
        }
    }

    async handleExpoer(ID) {
        let sql = await api("dataguru", { act: "data belajar", ID: ID }, true);
        if (sql.status == "sukses") {
            let Field = [
                { field: "NIS", text: "NIS" },
                { field: "Nama", text: "Nama Siswa" },
                { field: "Jam", text: "JamMulai" },
                { field: "Status", text: "Status" },
            ]
            exportData(this.state.DetailPelajaran, `Data kegiatan ${sql.data.NamaPelajaran}`, Field);
        }
    }

    render() {
        return (
            <Fragment>
                <div className='container'>
                    <h5 style={{ textAlign: "center" }}>Jadwal Pelajaran</h5>
                    <div className='table-responsive'>
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    <th>Jam</th>
                                    <th>Pelajaran</th>
                                    <th>Siswa</th>
                                    <th>Selesai</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.DataPelajaran.length > 0 ?
                                    this.state.DataPelajaran.map((tr, i) => {
                                        return (
                                            <tr key={i} onClick={() => this.handleDetailBelajar(tr.ID)}>
                                                <td>{tr.JamMulai}</td>
                                                <td>{tr.NamaPelajaran}</td>
                                                <td>{tr.Murid}</td>
                                                <td>{tr.JamSelesai}</td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan={4}>Tdak ada jadwal</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <h5 style={{ textAlign: "center" }}>Jadwal Ekstrakurikuler</h5>
                    <div className='table-responsive'>
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    <th>Mulai</th>
                                    <th>Ekstrakurikuler</th>
                                    <th>Murid</th>
                                    <th>Selesai</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.DataEks.length > 0 ?
                                    this.state.DataEks.map((tr, i) => {
                                        return (
                                            <tr key={i} onClick={() => this.handleDetailBelajar(tr.ID)}>
                                                <td>{tr.JamMulai}</td>
                                                <td>{tr.NamaPelajaran}</td>
                                                <td>{tr.Murid}</td>
                                                <td>{tr.JamSelesai}</td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan={4}>Tdak ada jadwal</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                <div style={{ position: "fixed", bottom: "100px", right: "20px" }}>
                    <button className="btn btn-default" style={{ borderRadius: "50%" }} onClick={() => this.handleFormPelajaran()}>
                        <i className="fas fa-plus"></i>
                    </button>
                </div>

                <div className="modal fade" id="modalAdd" tabIndex="-1" aria-labelledby="modalAbsen" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: "dataguru", debug: true, fn: () => this.handleMain() })} noValidate>
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Tambah Pelajaran</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <input type='hidden' name="act" value={this.state.ID == "" ? "tambah jadwal pelajaran" : "edit jadwal pelajaran"} />
                                    <input type='hidden' name="ID" value={this.state.ID} />
                                    <div className='form-group'>
                                        <label>Tanggal</label>
                                        <input type='date' name="Tanggal" className='form-control' value={this.state.Tanggal} onChange={(e) => this.setState({ Tanggal: e.target.value })} required />
                                        <div className='invalid-feedback'>Silahkan pilih tanggal</div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label>Jam Mulai</label>
                                                <input type="time" name="JamMulai" className='form-control' value={this.state.JamMulai} onChange={(e) => this.setState({ JamMulai: e.target.value })} required />
                                                <div className='invalid-feedback'>Silahkan tentukan jam mulai</div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label>Jam Selesai</label>
                                                <input type="time" name="JamSelesai" className='form-control' value={this.state.JamSelesai} onChange={(e) => this.setState({ JamSelesai: e.target.value })} required />
                                                <div className='invalid-feedback'>Silahkan tentukan jam selesai</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Mata Pelajaran / Eskul</label>
                                        <select name="IDPelajaran" className='form-select' value={this.state.IDPelajaran} onChange={(e) => this.setState({ IDPelajaran: e.target.value })} required>
                                            {
                                                this.state.DataMaPel.map((opt, i) => {
                                                    return (<option value={opt.ID}>{opt.Nama}</option>)
                                                })
                                            }
                                        </select>
                                        <div className='invalid-feedback'>Silahkan pilih mata pelajaran</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Note</label>
                                        <textarea name="Notes" className='form-control' value={this.state.Notes} onChange={(e) => this.setState({ Notes: e.target.value })} />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className='fas fa-save'></i>Absen</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDetail" tabIndex="-1" aria-labelledby="modalAbsen" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Detail Kegiatan</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='btnCloseDetail'></button>
                            </div>
                            <div className="modal-body">
                                <div className='d-flex justify-content-center align-item-center'>
                                    <QRCode value={this.state.ID} size={200} />
                                </div>
                                <div className='table-resposive'>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>NIS</th>
                                                <th>Nama</th>
                                                <th>Jam</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.DetailPelajaran.length > 0 ?
                                                this.state.DetailPelajaran.map((tr, i) => {
                                                    return (<tr key={i}>
                                                        <td>{tr.NIS}</td>
                                                        <td>{tr.Nama}</td>
                                                        <td>{tr.JamMulai}</td>
                                                        <td>{tr.Status}</td>
                                                    </tr>)
                                                })
                                                : <tr><td colSpan={4}>Tidak ada siswa</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-warning" onClick={() => this.handleFormPelajaran(this.state.ID)}><i className='fas fa-edit'></i> Edit</button>
                                <button type="button" className="btn btn-default" onClick={() => this.handleExpoer()}><i className='fas fa-download'></i> Download</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default Home;